import { axiosReq, setWebSocket } from '@/plugins/requester';
import type { APIRes, CorpFieldReq, CorpFieldRes, OAList, Page, WsReq } from '@/interface/Global';
import type { EtlVersion } from '@/interface/Etl';
import type { AccountReset, AccountResetReq, AccountVerify } from '@/interface/Account';
import type { GetTemplateListParams, Search } from '@/interface/TemplateList';
import type { SearchLineOaData } from '@/interface/LineBuilder';
import type { BuilderDeleteReq, SendLineTestData, SendTemplateTestData, TemplateBuilder } from '@/interface/TemplateBuilder';
import type { CategoryByID, HasRuleForm, NoRuleForm, SearchRuleRes, TagsByID, TagsByIDAPIRes, TrendData, TrendSearch, UpsertCategory } from '@/interface/Tags';
import type { CategorySearch, GetRefTagReq, GetRefTagRes, WsSearch as TagWsSearch, DeleteReq as TagsDeleteReq, Search as TagsSearch } from '@/interface/TagsList';
import type { ActScrtVerify, ActivitiesList, ActivityData, Search as ActivitySearch, CanvasLogReq, ScriptAuditReq, ScriptDetailFormReq, ScriptSetAuditReq, UsageStatus } from '@/interface/Activity';
import type { ExportTaData, RFMListItem, TaSearchByIdList, TaSearchByIdListRes } from '@/interface/Target';
import type { SaleReportDownload } from '@/interface/ReportProduct';
import type { ChannelStatus, LineOaForm, LineOaListReq, LineOaTest, RecordListSearch, TokenStatusForm } from '@/interface/MarketingChannel';
import type { URLAnalytics, URLAnalyticsSearch } from '@/interface/Analytics';
import type { ChannelFloodSetting, GlobalCurfewSetting, GlobalFloodSetting, SimulateSetting } from '@/interface/SendSetting';
import type { DownloadFileReq, DownloadFileRes, DownloadVerifyReq, DownloadVerifyRes } from '@/interface/Download';
import type { TargetSmart } from '@/interface/SmartList';

// 登入及權限
export const API_GET_USER = () => axiosReq().get('/cdp/auth/user_info');
export const API_GET_CORP_TOKEN = () => axiosReq().get('/cdp/auth/corp_token');
export const API_POST_LOGIN = (data: FormData) => axiosReq().post('/cdp/auth/token', data);
export const API_GET_CORP_FIELD = (data: CorpFieldReq) => axiosReq().post<APIRes<CorpFieldRes>>('/ma/users/get_corp_field', data);
export const API_GET_CORP_DYNAMIC_FIELD = () => axiosReq().get('/ma/users/get_corp_dynamic_field');

// 版號GET
export const API_TABLE_LAST_VERSION = () => axiosReq().get<APIRes<EtlVersion>>('/cdp/table_produce/last_complete_version');

// 總覽相關
export const API_OVERVIEW_GET_OVERVIEW = (data: any) => axiosReq().post('/cdp/overview/query_overview', data);
export const API_OVERVIEW_GET_PRODUCTLIST = (data: any) => axiosReq().post('/cdp/overview/query_hot_products_top10', data);
export const API_OVERVIEW_GET_ANALYTICS = (data: any) => axiosReq().post('/cdp/overview/query_user_analysis', data);
export const API_OVERVIEW_GET_USERPROFILE = (data: any) => axiosReq().post('/cdp/overview/query_user_profile', data);
export const API_OVERVIEW_GET_CAMPAIGN = (data: any) => axiosReq().post('/cdp/overview/query_campaign_recent10', data);
export const API_OVERVIEW_GET_PERIOD = (data: any) => axiosReq().post('/cdp/overview/query_hot_hours', data);

// 受眾相關
export const API_TARGET_GET = (data: any) => axiosReq().get(`/cdp/ta/get/${data.ta_id}`);
export const API_TARGET_GET_LIST = (data: any) => axiosReq().post(`/cdp/ta/search/${data.pg}/${data.pgs}`, data.body);
export const API_TARGET_GET_ALLLIST = (data: any) => axiosReq().post('/cdp/ta/search_all_id_name', data);
export const API_TARGET_UPSERET = (data: any) => axiosReq().post('/cdp/ta/custom/upsert', data.body);
export const API_TARGET_DELETE = (data: any) => axiosReq().delete('/cdp/ta/del_many', { data });
export const API_TARGET_SEARCH = (data: any) => axiosReq().post('/cdp/ta/search_ta_user_by_rules', data.body);
export const API_TARGET_GET_DETAIL = (data: any) => axiosReq().get(`/cdp/ta/get/${data.ta_id}`);
export const API_TARGET_EXPOORT = (data: ExportTaData[]) => axiosReq().post('/cdp/ta/export_data', data);
export const API_LOOKUP_GET_AGERANGE = () => axiosReq().get('/cdp/lookup/age_range');
export const API_LOOKUP_GET_PAYTYPE = () => axiosReq().get('/cdp/lookup/pay_type');
export const API_LOOKUP_GET_OFFICIAL_BOT = () => axiosReq().get('/cdp/lookup/official_bot_follower');
export const API_LOOKUP_GET_ORDER_REGION_CITY = () => axiosReq().get('/cdp/lookup/order_shipping_delivery');
export const API_LOOKUP_GET_PRODUCT = () => axiosReq().get('/cdp/lookup/product');
export const API_LOOKUP_GET_USER_TAG = () => axiosReq().get('/cdp/lookup/user_tag');
export const API_LOOKUP_GET_WEBLOG_ACTION = () => axiosReq().get('/cdp/lookup/weblog_action');
export const API_LOOKUP_GET_WEBLOG_CATEGORY = () => axiosReq().get('/cdp/lookup/weblog_category');
export const API_LOOKUP_GET_WEBLOG_LABEL = () => axiosReq().get('/cdp/lookup/weblog_label');
export const API_LOOKUP_GET_EVENT = () => axiosReq().get('/cdp/lookup/event');
export const API_LOOKUP_GET_SOURCE = () => axiosReq().get('/cdp/lookup/registered_source');
export const API_LOOKUP_GET_USER_LEVEL = () => axiosReq().get('/cdp/lookup/user_level');
export const API_LOOKUP_GET_STORE = () => axiosReq().get('/cdp/lookup/touch');
export const API_LOOKUP_GET_CREDIT = () => axiosReq().get('/cdp/lookup/elife_pay_bank');
export const API_LOOKUP_GET_CITY_DICT = () => axiosReq().get('/cdp/lookup/user_city_dict');
export const API_LOOKUP_GET_RFM = () => axiosReq().get<APIRes<RFMListItem[]>>('/cdp/lookup/rfm_percentile');
export const API_LOOKUP_GET_TAG_CAT = (data: any) => axiosReq().post('/cdp/user_tag/category/search', data);
export const API_LOOKUP_GET_TAG = (data: any) => axiosReq().post(`/cdp/user_tag/get_by_cat_id/${data.cat_id}`, data.body);
export const API_TARGET_GET_BY_ID_LIST = (data: TaSearchByIdList) => axiosReq().post<APIRes<Page<TaSearchByIdListRes>>>('/cdp/ta/search_by_id_list', data);
export const API_TARGET_GET_REPORT_TARGET = () => axiosReq().get('/cdp/ta/search_report_target');

// 智慧名單
export const API_TARGET_GET_SMARTLIST = (cat: string) => axiosReq().get<APIRes<Page<TargetSmart>>>(`/cdp/ta_smart/source/${cat}`);

// 發送分析
export const API_REPORT_GET_ACTIVITYLIST = () => axiosReq().get('/ma/report/get_mkt_act_list');
export const API_REPORT_GET_SCRIPTLIST = () => axiosReq().get('/ma/report/get_script_list');
export const API_REPORT_GET_TARGETLIST = () => axiosReq().get('/ma/report/get_ta_list');
export const API_REPORT_GET_TMPLLIST = () => axiosReq().get('/ma/report/get_template_list');
export const API_REPORT_GET_OALIST = () => axiosReq().get('/ma/report/get_oa_list');
export const API_REPORT_GET_ANALYTICS_LIST = (data: any) => axiosReq().post(`/ma/report/get_static_acc_list/${data.pg}/${data.pgs}`, data.body);
export const API_REPORT_GET_CHART = (data: any) => axiosReq().post('/ma/report/search_statistic_day_chart', data);
export const API_REPORT_GET_ANALYTICS_DETAIL = (data: any) => axiosReq().post('/ma/report/search_statistic_act_list', data);
export const API_REPORT_GET_URLLIST = (data: any) => axiosReq().post('/ma/report/search_url_list', data);
export const API_REPORT_GET_SENTLIST = (data: any) => axiosReq().post(`/ma/report/search_sent_list/${data.pg}/${data.pgs}`, data.body);
export const API_REPORT_EXPORT_SENTLIST = (data: any) => axiosReq().post('/ma/report/export_sent_list', data);
export const API_REPORT_STATISTIC_DAY_SENT = (data: any) => axiosReq().post('/ma/report/search_statistic_day_sent', data);
export const API_REPORT_DOWNLOAD_SENTLIST = (data: any) => axiosReq().post('/ma/dl/statsl_req', data);
export const API_REPORT_GET_ACTIVITY_INFO_LIST = (data: any) => axiosReq().post<APIRes<ActivitiesList>>('/ma/report/get_act_info_list', data);
export const API_REPORT_GET_SCRIPT_INFO_LIST = (data: any) => axiosReq().post('/ma/report/get_script_info_list', data);
export const API_REPORT_GET_SENT_RESULT = (data: any) => axiosReq().post('/ma/report/get_sent_result', data);
export const API_REPORT_GET_SENT_RECORD = (data: any) => axiosReq().post('/ma/report/get_sent_record', data);
export const API_REPORT_GET_SENT_GRAPHIC = (data: any) => axiosReq().post('/ma/report/get_sent_graphic', data);
export const API_REPORT_GET_URL_CLICK = (data: URLAnalyticsSearch) => axiosReq().post<APIRes<URLAnalytics[]>>('/ma/report/track_url_click_performance', data);

// 下載
export const API_DOWNLOAD_STATRPT_REQ = (data: any) => axiosReq().post('/ma/dl/statrpt_req', data);
export const API_DOWNLOAD_STATSL_REQ = (data: any) => axiosReq().post('/ma/dl/statsl_req', data);
export const API_DOWNLOAD_STATRPT_VERIFY = (data: DownloadVerifyReq) => axiosReq().post<APIRes<DownloadVerifyRes>>('/ma/dl/statrpt_verify', data);
export const API_DOWNLOAD_RESOURCE_GET = (data: DownloadFileReq) => axiosReq().post<APIRes<DownloadFileRes>>('/ma/dl/resource_get', data);
export const API_DOWNLOAD_REPORTLAB = (data: any) => axiosReq().post('/ma/dl/report_lab_req', data);
export const API_DOWNLOAD_SENT_REQ = (data: any) => axiosReq().post('/ma/dl/report_sent_req', data);
export const API_DOWNLOAD_SENT_CHANGE = (data: any) => axiosReq().post('ma/dl/export_report_sent_change', data);

// 防洪、宵禁
export const API_SYS_GET_CURFEW = () => axiosReq().get<APIRes<GlobalCurfewSetting>>('/ma/sys/get_curfew_setting');
export const API_SYS_SET_CURFEW = (data: GlobalCurfewSetting) => axiosReq().put('/ma/sys/set_curfew', data);
export const API_SYS_GET_FLOOD = () => axiosReq().get<APIRes<GlobalFloodSetting>>('/ma/sys/get_flood_setting');
export const API_SYS_SET_FLOOD = (data: GlobalFloodSetting) => axiosReq().put('/ma/sys/set_flood', data);
export const API_SYS_GET_FLOOD_SINGLE = () => axiosReq().get<APIRes<ChannelFloodSetting>>('/ma/sys/get_flood_single');
export const API_SYS_SET_FLOOD_SINGLE = (data: ChannelFloodSetting) => axiosReq().put('/ma/sys/set_flood_single', data);
export const API_SYS_GET_SENDTYPE = () => axiosReq().get<APIRes<SimulateSetting>>('/ma/sys/get_sending_type');
export const API_SYS_SET_SENDTYPE = (data: SimulateSetting) => axiosReq().put('/ma/sys/set_sending_type', data);
export const API_SYS_GET_RESTRICTED = () => axiosReq().get<APIRes<GlobalCurfewSetting>>('/ma/sys/get_curfew_restricted');

// 活動
export const API_ACTIVITY_GET_LIST = ({ page, pageSize, ...data }: ActivitySearch) => axiosReq().post<APIRes<Page<ActivityData>>>(`/ma/mkt_act/search/${page}/${pageSize}`, data);
export const API_ACTIVITY_GET_DETAIL = (data: any) => axiosReq().get(`/ma/mkt_act/get/${data.mktact_id}`);
export const API_ACTIVITY_ADD = (data: any) => axiosReq().post('/ma/mkt_act/add', data.body);
export const API_ACTIVITY_MODIFY = (data: any) => axiosReq().put(`/ma/mkt_act/set/${data.mktact_id}`, data.body);
export const API_ACTIVITY_COPY = ({ id, ...data }: any) => axiosReq().post(`/ma/mkt_act/add_copy/${id}`, data);
export const API_ACTIVITY_DELETE = (id: string) => axiosReq().delete(`/ma/mkt_act/del/${id}`);
export const API_ACTIVITY_CURRENT_LIST = () => axiosReq().get('/ma/mkt_act/get_current_info');
export const API_ACTIVITY_USAGE = () => axiosReq().get<APIRes<UsageStatus[]>>('/ma/mkt_act/get_usage_info');

// 劇本
export const API_ACTSCRTLIST_CREATE_SCRIPT = (data: any) => axiosReq().post(`/ma/act_scrt/add_scrt/${data.mktact_id}`, data.body);
export const API_ACTSCRTLIST_GET_SCRIPT = (id: string) => axiosReq().get(`/ma/act_scrt/get/${id}`);
export const API_ACTSCRTLIST_GET_SCRIPT_NEW_FIRST_EXEC_DT = (id: string) => axiosReq().get(`/ma/act_scrt/get/${id}/new_first_exec_dt`);
export const API_ACTSCRTLIST_SET_SCRIPT = ({ scrt_id, ...data }: ScriptDetailFormReq) => axiosReq().put(`/ma/act_scrt/set/${scrt_id}/${data.dup_id}`, data);
export const API_ACTSCRTLIST_SET_AUDIT_SCRIPT = ({ scrt_id, dup_id, set_status, ...data }: ScriptSetAuditReq) => axiosReq().put(`/ma/act_scrt/set_audit/${scrt_id}/${dup_id}/${set_status}`, data);
export const API_ACTSCRTLIST_VERIFY = ({ scrt_id, dup_id, ...data }) => axiosReq().post<APIRes<ActScrtVerify>>(`/ma/act_scrt/verify/${scrt_id}/${dup_id}`, data);
export const API_ACTSCRTLIST_AUDIT_SCRIPT = ({ scrt_id, dup_id, set_status, ...data }: ScriptAuditReq) => axiosReq().put(`/ma/act_scrt/audit/${scrt_id}/${dup_id}/${set_status}`, data);
export const API_ACTSCRTLIST_STOP_SCRIPT = ({ scrt_id, dup_id }) => axiosReq().post(`/ma/act_scrt/set_stop/${scrt_id}/${dup_id}`);
export const API_ACTSCRTLIST_DELETE_SCRIPT = (data: any) => axiosReq().delete(`/ma/act_scrt/del/${data.scrt_id}`);
export const API_ACTSCRTLIST_COPY_SCRIPT = (data: any) => axiosReq().post(`/ma/act_scrt/add_scrt_to_act/${data.mktact_id}/${data.scrt_id}`);
export const API_ACTSCRTLIST_COPY_DUPLICA = (data: any) => axiosReq().post(`/ma/act_scrt/add_copy_to_duplica/${data.scrt_id}/${data.dup_id}`, data.body);
export const API_ACTSCRTLIST_DELETE_DUPLICA = (data: any) => axiosReq().delete(`/ma/act_scrt/del_duplica/${data.dup_id}`);
export const API_ACTSCRTLIST_RENAME_DUPLICA = (data: any) => axiosReq().put(`/ma/act_scrt/set_scrt_name/${data.scrt_id}/${data.dup_id}`, data.body);
export const API_ACTSCRTLIST_LINE_TEST = (data: any) => axiosReq().post('/ma/simu/send_linescrt', data.body);
export const API_ACTSCRTLIST_OTHERS_TEST = (data: any) => axiosReq().post('/ma/simu/send_scrt_simu', data.body);

export const API_CANVAS_LOG_ADD = ({ mktact_id, scrt_id, dup_id, ...data }: CanvasLogReq) => axiosReq().post(`/ma/canvas_log/add/${mktact_id}/${scrt_id}/${dup_id}`, data);
export const API_CANVAS_LOG_GET = data => axiosReq().post(`/ma/canvas_log/get/${data.mktact_id}/${data.scrt_id}/${data.pg}/${data.pgs}`, data.body);

export const API_EXEC_LOG_GET = data => axiosReq().post(`/ma/exec_log/get/${data.mktact_id}/${data.scrt_id}/${data.pg}/${data.pgs}`, data.body);

export const API_EXEC_LOG_COUNT = data => axiosReq().get(`/ma/exec_logcnt/get/${data.mktact_id}/${data.scrt_id}/${data.dup_id}`);

export const API_VER_LOG_GET = data => axiosReq().get(`/ma/verlog/get/${data.mktact_id}/${data.scrt_id}`);

export const API_CUST_ATTR = () => axiosReq().get('/ma/customer/get_cust_attr');

// 儲值
export const API_GET_DEPOSIT_SMS = () => axiosReq('MGMP').get('/mgmp/deposit_ext/get_sms_status/0');
export const API_GET_DEPOSIT_EMAIL = () => axiosReq('MGMP').get('/mgmp/deposit_ext/get_email_status/0');
export const API_GET_DEPOSIT_SET = (data: TokenStatusForm) => axiosReq('MGMP').put('/mgmp/deposit_ext/set', data);
export const API_GET_DEPOSIT_RECORD = ({ channel, page, size, ...data }: RecordListSearch) => axiosReq('MGMP').get(`/mgmp/deposit_txn_ext/txn_record/${channel}/${page}/${size}`, { params: data });

// OA
export const API_OA_ADD = (data: LineOaForm) => axiosReq().post('/cdp/oa/add', data);
export const API_GET_LINE_OA_LIST = (data: SearchLineOaData) => axiosReq().post<APIRes<OAList>>('/cdp/oa/search', data);
export const API_OA_GET_LIST = ({ page, size, ...data }: LineOaListReq) => axiosReq().post(`/cdp/oa/search/${page}/${size}`, data);
export const API_OA_DELETE = (id: number) => axiosReq().delete(`/cdp/oa/del/${id}`);
export const API_OA_MODIFY = ({ id, ...data }: LineOaForm) => axiosReq().put(`/cdp/oa/set/${id}`, data);
export const API_OA_GET_THIRD_PARTY_STATUS = (data: ChannelStatus) => axiosReq().post('/cdp/oa/third_party_status/search', data);
export const API_OA_SEND_TEST = (data: LineOaTest) => axiosReq().post('/ma/simu/send_linelintmsg', data);

// 劇本模板
export const API_SCRTTEMP_GET_TEMPLATE_ALL = data => axiosReq().get(`/ma/scrt_template/get_template_all/${data.as_type}`);
export const API_SCRTTEMP_GET_TA_MA_FROM_SCRIPT = data => axiosReq().get(`/ma/scrt_template/get_ta_from_script_id/${data.scrt_id}`);
export const API_SCRTTEMP_GET_CHANNEL_ID = data => axiosReq().get(`/ma/scrt_template/get_channel_id/${data.mktact_id}/${data.scrt_id}/${data.dup_id}/${data.node_id}`);
export const API_TEMPLATE_CHECK_STATUS = data => axiosReq().post('/ma/scrt_template/check_template_status', data.body);

// 模板列表及內頁
export const API_TEMPLATE_GET_LIST = <T = any>(params: GetTemplateListParams, data: Search) => axiosReq().post<APIRes<T>>(`/ma/channel/search_template/${params.pg}/${params.pgs}`, data);
export const API_TEMPLATE_GET_DETAIL = (data: any) => axiosReq().get(`/ma/channel/get_template_by_id/${data.ct_id}`);
export const API_BUILDER_GET = <T = any>(id: string) => axiosReq().get<APIRes<T>>(`/ma/cb/get/${id}`);
export const API_BUILDER_ADD = (data: TemplateBuilder) => axiosReq().post<APIRes>('/ma/cb/add', data);
export const API_BUILDER_SAVE = (id: string, data: TemplateBuilder) => axiosReq().post<APIRes>(`/ma/cb/save/${id}`, data);
export const API_BUILDER_DELETE = (data: BuilderDeleteReq) => axiosReq().post<APIRes>('/ma/cb/del', data);
export const API_BUILDER_COPY = (id: string) => axiosReq().post<APIRes>(`/ma/cb/copy/${id}`);
export const API_BUILDER_SAVE_IMG = (data: FormData) => axiosReq().post<APIRes<string>>('/ma/cb/saveimg', data);
export const API_BUILDER_DELETE_IMG = <T = any>(data: any) => axiosReq().post<APIRes<T>>('/ma/cb/deleteimg', data);
export const API_BUILDER_LINE_TEST = (data: SendLineTestData<string[]>) => axiosReq().post<APIRes>('/ma/simu/send_linetest', data);
export const API_BUILDER_TEST = <T = any>(data: SendTemplateTestData<T, string[]>) => axiosReq().post<APIRes>('/ma/simu/send_template_simu', data);

// 帳號
export const API_ACCOUNT_GET_LIST = data => axiosReq().post(`/ma/account/search/${data.pg}/${data.pgs}`, data.body);
export const API_ACCOUNT_GET_INFO = data => axiosReq().post(`/ma/account/get/${data.account_id}`);
export const API_ACCOUNT_ADD_Single = data => axiosReq().post('/ma/account/add', data);
export const API_ACCOUNT_SET_Single = data => axiosReq().post('/ma/account/change_role', data);
export const API_ACCOUNT_DELTE = data => axiosReq().delete(`/ma/account/del/${data.account_id}`);
export const API_ACCOUNT_RESET_REQ = (data: AccountResetReq) => axiosReq().post('/ma/account/reset/req_password', data);
export const API_ACCOUNT_VERIFY = (data: AccountVerify) => axiosReq().post('/ma/account/verify/token', data);
export const API_ACCOUNT_RESET = (data: AccountReset) => axiosReq().post('/ma/account/reset/password', data);

// 會員資訊
export const API_USERINFO_GET_INFO = data => axiosReq().post('/cdp/user_info/get_user_info', data);
export const API_USERINFO_GET_PAGEVIEW = data => axiosReq().post('/cdp/user_info/get_pageview', data);
export const API_USERINFO_GET_WEBLOG = data => axiosReq().post('/cdp/user_info/get_web_log', data);
export const API_USERINFO_GET_BEHAVIOR = data => axiosReq().post('/cdp/user_info/get_user_bhv', data);
export const API_USERINFO_GET_PRDFAV = data => axiosReq().post('/cdp/user_info/get_user_fav_prd', data);
export const API_USERINFO_GET_PRDBUY = data => axiosReq().post('/cdp/user_info/get_user_buy_prd', data);
export const API_USERINFO_DOWNLOAD_PRDBUY = data => axiosReq().post('/cdp/user_info/export_user_buy_prd', data);
export const API_USERINFO_SET_SUB = data => axiosReq().post('/cdp/user_info/user_subscribe', data);
export const API_USERINFO_GET_TAG = data => axiosReq().post('/cdp/user_info/get_user_tag', data);

// 受眾描述
export const API_TADESC_PRODUCTS_TEN = data => axiosReq().post('/cdp/ta_desc/query_hot_products_top10', data);
export const API_TADESC_SALES = data => axiosReq().post('/cdp/ta_desc/query_sales', data);
export const API_TADESC_TRANS = data => axiosReq().post('/cdp/ta_desc/query_num_of_days_between_trans', data);
export const API_TADESC_REPURCHASE = data => axiosReq().post('/cdp/ta_desc/query_repurchase', data);
export const API_TADESC_USER_PROFILE = data => axiosReq().post('/cdp/ta_desc/query_user_profile', data);
export const API_TADESC_RFM = data => axiosReq().post('/cdp/ta_desc/query_rfm', data);
export const API_TADESC_PRD_CAT = data => axiosReq().post('/cdp/ta_desc/query_prd_cat', data);

// 報表實驗室
export const API_LAB_DEVICE = data => axiosReq().get(`/cdp/lab_report/weblog_device/total/${data.start_dt}/${data.end_dt}`);
export const API_LAB_DEVICE_DAILY = data => axiosReq().get(`/cdp/lab_report/weblog_device/daily/${data.start_dt}/${data.end_dt}`);

export const API_LAB_BROWSE = data => axiosReq().get(`/cdp/lab_report/weblog_source/total/${data.start_dt}/${data.end_dt}`);
export const API_LAB_BROWSE_DAILY = data => axiosReq().get(`/cdp/lab_report/weblog_source/daily/${data.start_dt}/${data.end_dt}`);
export const API_LAB_BROWSE_DETAIL = data => axiosReq().get(`/cdp/lab_report/weblog_source/detail/${data.start_dt}/${data.end_dt}`);

export const API_LAB_PAGES = data => axiosReq().get(`/cdp/lab_report/weblog_pages/total/${data.start_dt}/${data.end_dt}`);
export const API_LAB_PAGES_DAILY = data => axiosReq().get(`/cdp/lab_report/weblog_pages/daily/${data.start_dt}/${data.end_dt}`);
export const API_LAB_PAGES_DETAIL = data => axiosReq().get(`/cdp/lab_report/weblog_pages/detail/${data.start_dt}/${data.end_dt}/${data.pg}/${data.pgs}`);

export const API_LAB_RFM = data => axiosReq().post('/cdp/lab_report/rfm', data);

export const API_LAB_ORD_MONTHLY = data => axiosReq().post('/cdp/ta_ord_report/trend_daily', data);
export const API_LAB_ORD_YEAR = data => axiosReq().post('/cdp/ta_ord_report/trend_monthly', data);
export const API_LAB_CATE_SALES = data => axiosReq().post('/cdp/ta_ord_report/cat1_sale_analysis', data);
export const API_LAB_CUSTOM_PRODUCT_ANALYTICS = data => axiosReq().post('/cdp/ta_ord_report/prod_sale_analyze', data);
export const API_LAB_CUSTOM_PRODUCT_SALERANK = data => axiosReq().post('/cdp/ta_ord_report/prod_sale_rank', data);
export const API_LAB_STORE_ANALYTICS = data => axiosReq().post('/cdp/ta_ord_report/sales_analysis_by_touch', data);
export const API_LAB_GET_PRODUCTS = data => axiosReq().post(`/cdp/product/search/${data.pg}/${data.pgs}`, data.body);
export const API_LAB_SALE_REPORT_DOWNLOAD = ({ type, ...data }: SaleReportDownload) => axiosReq().post(`/cdp/ta_ord_report/sale_analyze/${type}/download`, data);

export const API_LAB_GET_TA_PROFILE = data => axiosReq().post('/cdp/ta_compare/profile', data);
export const API_LAB_GET_TA_DAILY = data => axiosReq().post('/cdp/ta_compare/ta_sale_daily', data);
export const API_LAB_GET_TA_MONTHLY = data => axiosReq().post('/cdp/ta_compare/ta_sale_monthly', data);

export const API_INSIGHT_SEARCH = data => axiosReq().post(`/cdp/report_insight/search/${data.pg}/${data.pgs}`, data.body);
export const API_INSIGHT_CHECK_ADD = () => axiosReq().post(`/cdp/report_insight/check_add`);
export const API_INSIGHT_ADD = data => axiosReq().post(`/cdp/report_insight/add`, data);
export const API_INSIGHT_GET = data => axiosReq().get(`/cdp/report_insight/${data.report_id}`);
export const API_INSIGHT_DELETE = data => axiosReq().delete(`/cdp/report_insight/delete/${data.report_id}`);

// 標籤
export const API_TAGS_CATEGORY_LIST = <T = any>(data: CategorySearch) => axiosReq().post<APIRes<T>>('/cdp/user_tag/category/search', data);
export const API_TAGS_CATEGORY_GET_DATA = <T = any>(data: CategoryByID) => axiosReq().post<APIRes<T>>('/cdp/user_tag/category/search_by_cat_id', data);
export const API_TAGS_CATEGORY_UPSERT = (data: UpsertCategory) => axiosReq().post<APIRes>('/cdp/user_tag/category/upsert', data);
export const API_TAGS_CATEGORY_DELETE = (data: UpsertCategory) => axiosReq().post<APIRes>('/cdp/user_tag/category/delete', data);
export const API_TAGS_LIST = <T = any>({ page, pageSize, ...data }: TagsSearch) => axiosReq().post<APIRes<T>>(`/cdp/user_tag/search/${page}/${pageSize}`, data);
export const API_TAGS_GET_DATA_HAS_LIST = ({ tag_id, page, pageSize, ...data }: TagsByID) => axiosReq().post<TagsByIDAPIRes>(`/cdp/user_tag/get_by_tag_id/${tag_id}/${page}/${pageSize}`, data);
export const API_TAGS_GET_DATA = <T = any>(data: { tag_id: number }) => axiosReq().post<APIRes<T>>('/cdp/user_tag/search_by_tag_id', data);
export const API_TAGS_SEARCH_RULE = <T = any>(data: SearchRuleRes) => axiosReq().post<APIRes<T>>('/cdp/user_tag/search_by_rule', data);
export const API_TAGS_GET_TREND = (data: TrendSearch) => axiosReq().post<APIRes<TrendData[]>>('/cdp/user_tag/get_member_cnt_daily', data);
export const API_TAGS_UPLOAD = <T = any>(data: FormData) => axiosReq().post<APIRes<T>>('/cdp/user_tag/add_tag_file_upload', data);
export const API_TAGS_UPSERT_BY_RULE = (data: HasRuleForm) => axiosReq().post<APIRes>('/cdp/user_tag/upsert_by_rule', data);
export const API_TAGS_UPSERT = (data: NoRuleForm) => axiosReq().post<APIRes>('/cdp/user_tag/upsert', data);
export const API_TAGS_EDIT = (data: NoRuleForm) => axiosReq().post<APIRes>('/cdp/user_tag/edit', data);
export const API_TAGS_DELETE = (data: TagsDeleteReq) => axiosReq().post<APIRes>('/cdp/user_tag/del_many', data);
export const API_TAGS_GET_MA_REF = (data: GetRefTagReq) => axiosReq().post<APIRes<GetRefTagRes[]>>('/cdp/user_tag/get_ref_tag_data', data);

// WebSocket
export const WS_TARGET_GET_LIST = ({ pg, pgs, user_id, session }: WsReq, onMessage: (event: any) => void) => setWebSocket(`/ma/websocket/ta/search/${pg}/${pgs}?user_id=${user_id}&session=${session}`, onMessage);
export const WS_REPORT_GET_ANALYTICS_LIST = ({ pg, pgs, user_id, session }: WsReq) => setWebSocket(`/ma/websocket/report/get_static_acc_list/${pg}/${pgs}?user_id=${user_id}&session=${session}`);
export const WS_REPORT_GET_CHART = ({ user_id, session }: WsReq) => setWebSocket(`/ma/websocket/report/search_statistic_day_chart?user_id=${user_id}&session=${session}`);
export const WS_REPORT_GET_ANALYTICS_DETAIL = ({ user_id, session }: WsReq) => setWebSocket(`/ma/websocket/report/search_statistic_act_list?user_id=${user_id}&session=${session}`);
export const WS_ACC_LOGOUT = (data: any, onMessage: (event: any) => void) => setWebSocket('/ma/websocket/account/logout', onMessage);
export const WS_TAGS_LIST = ({ page, pageSize, user_id, session }: TagWsSearch, onMessage: (event: any) => void) => setWebSocket(`/ma/websocket/user_tag/search/${page}/${pageSize}?user_id=${user_id}&session=${session}`, onMessage);
